// If you want to override variables do it here
@import "variables";

// fontawesome
@import '~@fortawesome/fontawesome-free/css/all.min.css';

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";


// Slick carousel
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

// If you want to add something do it here
@import "custom";

