.custom-sidebar {
  background: #298EB3 !important;
  .c-sidebar-brand {
    background: #123D4D !important;
  }
  .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar-nav-link.c-active, .c-sidebar-nav-dropdown.c-show {
    background: #12536b !important;
  }
  .c-sidebar-nav-dropdown.c-show {
    padding-bottom: 16px;
  }

  .c-sidebar-nav-link:hover, .c-sidebar-nav-dropdown-toggle:hover {
    background: #206F8C !important;
  }
  .c-sidebar-minimizer {
    background: #123D4D !important;
  }

}

.c-sidebar-nav-dropdown-items {
  overflow: hidden;
  .c-sidebar-nav-item {
    .c-sidebar-nav-link {
      flex-direction: column;
      place-items: baseline;
      .description {
        font-size: small;
        clear: none;
        flex: initial;
        white-space: initial;
        color: #999;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &.c-sidebar-nav-item-cus{
      .c-sidebar-nav-link{
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 12px;
      }
    }
  }
}
